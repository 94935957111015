.true_button_container {
  font-size: var(--t-body-size);
  padding: 4px 10px;
  text-transform: uppercase;
}

/*contained*/
:global(.true_button_container.true_button_contained) {
  background-color: var(--t-primary);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: var(--t-primary-background);
  border-color: var(--t-border-color) !important;
}

:global(.true_button_container.true_button_contained:hover) {
  background-color: var(--t-primary-50);
  /*opacity color 60 do not exists*/
}

:global(.true_button_container.true_button_contained.disabled) {
  background-color: var(--t-contrast-background-10);
  /*opacity color 8 do not exists*/
  color: var(--t-text-color-40);
  /*text color opacity 38 do not exists*/
  box-shadow: none !important;
}

/*outlined*/
:global(.true_button_container.true_button_outlined) {
  border-color: var(--t-primary) !important;
  background-color: var(--t-primary-background);
  color: var(--t-primary);
}

:global(.true_button_container.true_button_outlined:hover) {
  background-color: var(--t-primary-10);
  color: var(--t-secondary-text-color);
  /*opacity color 60 do not exists*/
}

:global(.true_button_container.true_button_outlined.disabled) {
  /*text color opacity 38 do not exists*/
  border-color: var(--t-text-color-40) !important;
  color: var(--t-text-color-40) !important;
  box-shadow: none !important;
}

/*text*/
:global(.true_button_container.true_button_text) {
  background-color: transparent;
  color: var(--t-primary);
  font-size: var(--t-body-size);
  font-weight: var(--t-body-bold-weight);
}

:global(.true_button_container.true_button_text:hover) {
  background-color: var(--t-primary-10);
}
