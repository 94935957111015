.list_document_container {
  text-align: left;
}

.content_container {
  flex-grow: 1;
  overflow: hidden;
}

:global(.document_container) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 5px;
  margin: 10px 0;
}

:global(.document_container .MuiCheckbox-root) {
  padding: 0 !important;
}
