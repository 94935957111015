:global(.true_input_warning_icon_wrapper) {
    display: flex;
    width: 100%;
}

:global(.true_input_warning_icon_wrapper .true_input_warning_icon) {
    align-self: flex-end;
    margin-left: -24px;
    z-index: 1;
    color: var(--t-danger) !important;
}

:global(.true_input_warning_icon_wrapper .true_input_with_icon) {
    padding-right: 15px;
}