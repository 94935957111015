.bound_policies_container {
  margin-top: 10px;
}

.bound_policy_title,
.bound_policy_content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bound_policies_content_container {
  margin-top: -7px;
}

.bound_policy_content {
  padding-right: 24px;
  margin-bottom: 10px;
}

.bound_policy_content > *,
.bound_policy_title > * {
  width: 20%;
  text-align: left !important;
}

.second_column {
  width: 10%;
}

@media (max-width: 345px) {
  .bound_policy_content > *,
  .bound_policy_title > * {
    width: 100%;
  }
}

@media (max-width: 635px) {
  .bound_policy_content > *,
  .bound_policy_title > * {
    width: 50%;
  }
}
