.account_and_balance_table_wrapper {
    margin-top: 20px;
    display: flex;
    max-height: 168px;
    overflow-y: scroll;
}

.account_and_balance_table_wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.account_and_balance_table_wrapper::-webkit-scrollbar-track {
    background: transparent;
}

.account_and_balance_table_wrapper::-webkit-scrollbar-thumb {
    background: var(--t-secondary-background);
    border-radius: 5px;
}

.account_and_balance_table_wrapper::-webkit-scrollbar-thumb:hover {
    background: var(--t-secondary-background);
}

.account_and_balance_fee_row {
    border-bottom: 1px solid var(--t-secondary-background-30);;
}