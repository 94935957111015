.button_collapse_primary {
  background: transparent !important;
  width: 100%;
  margin: 10px 0px !important;
}

.button_collapse_content_primary {
  background: transparent;
}

:global(.title_button_collapse_primary) {
  padding: 0 !important;
  align-items: flex-start !important;
  min-height: 38px !important;
}

:global(.title_button_collapse_primary .MuiAccordionSummary-content) {
  justify-content: center;
  text-transform: uppercase;
  color: var(--t-primary);
  font-weight: 700;
  background-color: #3f51b529 !important;
  margin: 0 !important;
  padding: 9px 16px;
  border-radius: 4px;
  min-height: 38px !important;
}

.double_button_collapse_container {
  width: 100%;
}

.double_button_collapse_buttons_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.double_button_collapse {
  width: 49.5%;
  padding: 9px 16px;
  border-radius: 4px;
  min-height: 38px !important;
  font-weight: 700;
  background-color: #2d2d461f !important;
  margin: 0px 0px 10px 0px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.double_button_selected_collapse {
  background-color: #2d2d464d !important;
}

.first_button_gap {
  margin-right: 10px;
}

.double_button_collapse_content_container {
  overflow: hidden;
  transition: "height 0.3s ease-in-out";
  padding: 8px 16px 16px;
}
