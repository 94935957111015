.main_nav .MuiTabs-indicator {
  background-color: #33a0ff;
}

#nav_button {
  background-color: transparent;
  border-color: #f6f6f9 !important;
  color: #f6f6f9;
  margin: 0 10px;
}

.portals-nav-item,
.top-link,
.top-link span {
  color: #f6f6f9 !important;
}

.top-link a {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.icons-top-bar {
  display: flex;
  justify-content: flex-end;
  height: 37px;
  align-items: center;
  flex-wrap: wrap;
}

.icons-top-bar-container {
  display: flex;
  gap: 10px;
}

@media (max-width: 485px) {
  .top-link a span.MuiTypography-root {
    display: none;
  }
  .icons-top-bar {
    justify-content: center;
  }
  .icons-top-bar-container {
    gap: 30px;
  }

  .icons-top-bar-container .k-icon {
    font-size: 16px !important;
  }
}

.logo-container {
  cursor: pointer;
  align-self: baseline;
}

@media (max-width: 657px) {
  .portals-nav-item,
  .top-link,
  .top-link span {
    font-size: 12px !important;
  }

  .top_nav_items_container button {
    font-size: 10px !important;
    padding: 4px !important;
  }
  .logo-container {
    flex-grow: 1;
  }
}
