.date_input {
  background-color: var(--t-secondary);
  border: none;
  border-bottom: 1px solid var(--t-border-color);
  padding: 8px;
  text-transform: uppercase;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.date_input:focus {
  outline-width: 0;
}
