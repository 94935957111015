.true_breadcrumb_container {
    width: 100%;
    text-align: left;
}

:global(div[class*="breadcrumb_container"] .MuiBreadcrumbs-ol) {
    flex-wrap: nowrap !important;
    text-overflow: ellipsis;

}

:global(div[class*="breadcrumb_container"] .MuiBreadcrumbs-ol svg) {
    color: var(--t-text-color) !important;
}

:global(div[class*="breadcrumb_container"] .true_breadcrumb_chip_items) {
    color: var(--t-text-color) !important;
    border: 1px solid var(--t-text-color) !important;
    border-radius: 5px;
    width: auto;
    margin-right: 30px;
    padding: 1px;
    vertical-align: middle;
    height: fit-content;
}

.true_breadcrumb_link {
    text-shadow: none !important;
    ;
    color: var(--t-secondary-background) !important;
}

.true_breadcrumb_link_active {
    text-shadow: 0 0 1px var(--t-primary) !important;
}

.true_breadcrumb_link_active_or_completed {
    color: var(--t-primary) !important;
}