.collapse_children_container::-webkit-scrollbar {
  width: 5px;
}

.collapse_children_container::-webkit-scrollbar-track {
  background-color: transparent;
}

.collapse_children_container::-webkit-scrollbar-thumb {
  background-color: var(--t-secondary-background);
  border-radius: 5px;
}

.collapse_children_container::-webkit-scrollbar-thumb:hover {
  background-color: var(--t-secondary-background);
}
