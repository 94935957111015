.with_letters {
  color: var(--t-primary);
  transform: rotate(270deg);
  font-size: var(--t-heading-6-size);
  width: fit-content;
  height: fit-content;
}

.agency_name {
  font-size: var(--t-heading-5-size);
  width: 40%;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
