.row-grid-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.row-grid-wrapper .col-grid-wrapper .true-input-general-container,
.row-grid-wrapper .col-grid-wrapper .true-select-general-container,
.row-grid-wrapper .col-grid-wrapper .true-input-container {
  width: 100%;
}

.row-grid-wrapper.MuiGrid-root {
  flex-wrap: nowrap;
}

@media (max-width: 600px) {
  .row-grid-wrapper {
    flex-wrap: wrap !important;
  }
}
