.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.hazard-background {
  height: 30px;
  color: white;
  padding: 5px;
  background-image: repeating-linear-gradient(-55deg,
      #000,
      #000 20px,
      #ffb101 20px,
      #ffb101 40px);
}

.hazard-border {
  border: 20px solid pink;
  border-image: repeating-linear-gradient(-55deg,
      #000,
      #000 20px,
      #ffb101 20px,
      #ffb101 40px) 10;
}