:global(.true_upload_container .true_upload_error_message) {
    color: var(--t-danger);
    font-size: var(--t-small-title-size);
    letter-spacing: 0.03333em;
    text-align: left;
    display: block;
    padding-top: 4px;

}

:global(.true_upload_container .k-upload-files::-webkit-scrollbar) {
    width: 5px;

}

:global(.true_upload_container .k-upload-files::-webkit-scrollbar-track) {
    background-color: transparent;
}

:global(.true_upload_container .k-upload-files::-webkit-scrollbar-thumb) {
    background-color: var(--t-secondary-background);
    border-radius: 5px;
}

:global(.true_upload_container .k-upload-files::-webkit-scrollbar-thumb:hover) {
    background-color: var(--t-secondary-background);
}

:global(.true_upload_container .k-actions button) {
    font-size: var(--t-body-size);
    padding: 4px 10px;
    text-transform: uppercase;
}

:global(.true_upload_container .k-actions .k-clear-selected) {
    background-color: transparent;
    color: var(--t-primary);
    font-size: var(--t-body-size);
    font-weight: var(--t-body-bold-weight);
}

:global(.true_upload_container .k-actions .k-clear-selected:hover) {
    background-color: var(--t-primary);
}


:global(.true_upload_container.upload_button_visible .k-upload-selected) {
    display: inherit;
}

:global(.true_upload_container .k-upload-selected) {    
    font-size: var(--t-body-size);
    background-color: var(--t-primary);
    border-color: var(--t-border-color);
}

:global(.true_upload_container .k-upload-selected:hover) {
    background-color: var(--t-primary-50);
}