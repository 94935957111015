.add_new_incident_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.add_incident_fields_container {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-grow: 1;
}
.left_incident_wrapper {
  overflow-y: auto;
  min-width: 210px;
  padding: 3px;
}

.right_incident_wrapper {
  padding: 1;
  flex-grow: 1;
  align-items: "auto";
}

.incident_actions_and_fields {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.incident_field_container {
  width: 100%;
  justify-content: space-between;
  display: flex;
  overflow: auto;
  max-height: 510px;
  margin-bottom: 20px;
}

.incident_fields_content {
  flex-grow: 1;
}

.instructions {
  color: var(--t-text-color);
}

.finish_instructions {
  overflow-y: auto;
  max-height: 125px;
  padding-right: 10px;
}
