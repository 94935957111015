:root {
    --blue-icon: #66a4e1;
    --blue-link: #1785D8;
    --light-gray: #847F7F;
    --beige-background: #F5F5F5;
    --default-font-size-input: 14px;
    --default-font-size: 16px;
    --default-line-height: 24px;
    --table-header-color: rgba(0, 0, 0, 0.54);
    --light-gray-border-color: rgba(0, 0, 0, 0.12);
    --light-gray2-border-color: rgba(0, 0, 0, 0.24);
    --label-font-size: 14px;

    --blue-link-rgb-12: rgba(23, 133, 216, 0.12);

    --header-color: rgba(132, 127, 127, 0.87);
    --header-link-color: #3F51B5;

    --extra-small: 0px;
    --small: 600px;
    --medium: 900px;
    --large: 1200px;
    --extra-large: 1536px;
}