.download_file_general_wrapper {
display: flex;
padding: 5px 10px;
}

.download_file_wrapper {
  cursor: pointer;
  display: flex;
  width: fit-content !important;
  height: fit-content !important;
  align-items: flex-end;
}

.file_name_download {
  padding-top: "5px";
}

.delete_file_icon {
  font-size: 12px !important;
  cursor: pointer;
  margin-left: 10px;
  color: red !important;
}
