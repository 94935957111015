.payroll_report_header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px;
}

.payroll_report_body_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
}

.payroll_report_details_buttons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin: 10px 0;
  align-items: center;
}
