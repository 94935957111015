.true_collapse_wrapper.multi_table:not("transparent") .true_collapse {
  /* border: 10px solid black; */
  background-color: rgba(142, 142, 164, 0.3);
  /* border-radius: 0px; */
}

.true_collapse_wrapper.multi_table.transparent .true_collapse {
  border-top: none;
  border-left: none;
  border-right: none;
}

.true_collapse_wrapper.multi_table.transparent.expanded .true_collapse_title_accordion {
  border-bottom: 1px solid rgba(142, 142, 164, 0.3);
  font-weight: 700;
}

.true_collapse_wrapper.multi_table.transparent .true_collapse .true_table_body_data {
  border: none;
}

.collapse_wrapper.multi_table.normal.expanded
  .collapse
  .collapse_title_accordion {
  border-bottom: 1px solid var(--t-secondary-background-10);
  font-weight: 700;
  background-color: var(--t-secondary-background-30);
}
