:global(div.true_input_tax) {
    display: flex;
    width: 100%;
}

:global(div.true_input_tax .true_input_general_container) {
    width: 100%;

}

:global(div.true_input_tax.true_input_tax_read_only .true_input_general_container) {
    width: fit-content !important;
}

:global(div.true_input_tax .true_input_general_container .true_input_container.read-only) {
    width: fit-content !important;
}


:global(div.true_input_tax .true_input_general_container .true_input_container.read-only .true_input_label) {
    width: 100%;
}

:global(div.true_input_tax.true_input_tax_read_only .true_input_general_container .true_input_container.read-only .true_input_label) {
    width: fit-content !important;
}

:global(div.true_input_tax .true_input_general_container .true_input_container.read-only .true_input.read-only) {
    width: fit-content !important;
}

:global(div.true_input_tax .true_input_general_container .true_input_container.read-only .true_input.read-only input) {
    width: 100%;
}

:global(div.true_input_tax.true_input_tax_read_only .true_input_general_container .true_input_container.read-only .true_input.read-only) input {
    width: 100px !important;
}

:global(div.true_input_tax .true_icons_container) {
    display: flex;
    align-items: flex-end;
}

:global(div.true_input_tax .true_icons_container svg) {
    width: 16px;
    height: 16px;
    color: var(--t-text-color);
}

:global(div.true_input_tax .true_icons_container >button) {
    transform: translateY(-3px);
}