/* Animate Flip Text */
div.flip-animate {
  perspective: 1000px;
}
div.flip-animate div {
  position: relative;
  display: inline-block;
  padding: 0;
  transition: transform 0.3s;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
}
div.flip-animate div:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  content: attr(data-hover);
  transition: color 0.3s;
  transform: rotateX(-90deg);
  transform-origin: 50% 0;
  text-align: center;
}
div.flip-animate:hover div,
div.flip-animate:focus div {
  transform: rotateX(90deg) translateY(-22px);
}
div.flip-animate:hover div:before,
div.flip-animate:focus div:before {
  text-align: left;
  color: #d24936;
}
