.default_render_only_cell {
  /* height: "42px"; */
  /* padding-left: 3px; */
  width: 100%;
}

.default_date_render_only_cell {
}

.default_select_render_only_cell {
  /* padding-left: 12px; */
}

.default_checkbox_render_only_cell {
  width: 24px;
  margin-left: 9px;
  margin-top: 6px;
}

:global(.default_checkbox_render_only_cell_path) {
  fill: var(--t-border-color);
}

.default_currency_render_only_cell {
  width: 100%;
  text-align: right;
}

.default_link_render_only_cell {
  text-decoration: none;
  color: var(--t-primary);
  cursor: pointer;
}

.default_tag_render_only_cell {
}

.default_phone_render_only_cell {
  /* padding-left: 3px; */
}

.default_phone_ext_render_only_cell {
  padding-left: 10px;
}

.default_phone_ext_render_only_cell {
  font-size: 10px;
  font-weight: 700;
  display: inline-block;
  margin-right: 4px;
}

.default_phone_ext_value_render_only_cell {
  display: inline-block;
}

.checkbox_active_render_only_cell {
}

.checkbox_inactive_render_only_cell {
}
