:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper) {
    border-width: 1px;
    border-radius: 0;
    border-color: var(--t-border-modal-color);
    background-color: var(--t-primary-background);
    margin: 0;
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper #draggable-dialog-title) {
    background-color: var(--t-contrast-background);
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper #draggable-dialog-title .MuiTypography-root) {
    color: #fff;
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper #draggable-dialog-title svg) {
    color: #fff;
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper #draggable-dialog-title button) {
    position: relative;
    display: block;
    right: 0;
    top: 0;
    font-size: var(--t-body-size);
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper .MuiDialogContent-root) {
    padding: 24px 24px 0 24px !important;
    background-color: var(--t-primary-background);
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper .MuiDialogActions-root) {
    background-color: var(--t-primary-background);
    padding: 24px;
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper .MuiDialogActions-root .true_modal_button_delete_container) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper .MuiDialogActions-root .true_modal_button_extra_container) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 15px;
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper .MuiDialogActions-root .true_modal_button_delete_container.button_ribbon_node) {
    width: auto;
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper .MuiDialogActions-root .true_modal_button_ribbon_node_container) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: auto;
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper .MuiDialogActions-root .true_modal_button_ribbon_node_container.button_ribbon_node_container) {
    width: 100%;
}

:global(.true_modal_dialog_container .MuiPaper-root.MuiDialog-paper .MuiDialogActions-root .true_modal_button_ribbon_node_container .true_modal_node) {
    flex-grow: 1;
}