.true_table_pdf_toolbar_container {
  position: absolute;
  left: -1px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;

}

.true_table_pdf_container .hideColumn {
  display: none;
}

.true_table_pdf_container th {
  font-size: 10px;
  padding: 3px 5px;
}

.true_table_pdf_container td {
  font-size: 8px;
  padding: 3px 5px;
  vertical-align: middle;
}

.true_table_pdf_container td a {
  color: #1976d2;
}