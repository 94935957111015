.true_conversation_wrapper {
  overflow: auto;
  width: 100%;
  padding-left: 20px;
}

.true_conversation_wrapper::-webkit-scrollbar {
  width: 5px;
}

.true_conversation_wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.true_conversation_wrapper::-webkit-scrollbar-thumb {
  background-color: var(--t-secondary-background);
  border-radius: 5px;
}

.true_conversation_wrapper::-webkit-scrollbar-thumb:hover {
  background-color: var(--t-secondary-background);
}

.true_note {
  width: 100%;
  margin-top: 10px;
}

.true_message {
  margin: 8px 16px 8px 2px;
  font-size: var(--t-body-size);
  padding: 8px;
  width: 200px;
  flex-grow: 1;
  border-radius: 10px 10px 10px 0 !important;
  width: 200px;
  max-width: 90%;
}

.true_message_content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.true_note_information {
  margin-right: 15px;
  color: var(--t-text-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message_description {
  display: flex;
  margin-left: 25px;
  margin-bottom: -5px;
  color: var(--t-text-color);
}

.true_empty_message_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.true_empty_message_container {
  color: var(--t-secondary-background);
  font-style: italic;
}
