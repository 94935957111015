.true_score_chip_button {
  margin: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
  color: white !important;
  min-width: 0px !important;
}

.true_score_chip_button:hover {
  cursor: context-menu !important;
}

.clickable:hover {
  cursor: pointer !important;
}

.small {
  padding: 1.5px 7px !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  border-radius: 3px !important;
}

.medium {
  padding: 2px 11px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
}

.big {
  padding: 3px 15px !important;
  font-size: 20px !important;
  border-radius: 5px !important;
}