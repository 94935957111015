.section_container {
    display: flex;
    margin-left: 30px; 
    margin-top: 5px; 
    margin-bottom: 5px; 
    padding: 0px 70px;
}

.file_upload_container {
    display: flex;
    width: 90%;
   
}

.buttons_container {
    display: flex;    
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
}