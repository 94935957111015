.billing_components_container {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  height: 96%;
}

.container_billing_header_information {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.container_billing_header_information_landing {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
