:global(.true_input_general_container .true_input_container) {
  display: flex;
  align-items: center;
}

:global(.true_input_general_container .true_input_container.search) {
  position: relative;
  display: flex !important;
  align-items: center;
}

:global(.true_input_general_container .true_input_container.search svg) {
  color: var(--t-primary);
  font-size: var(--t-title-size);
  position: absolute;
  right: 0;
  margin-top: 15px;
}

:global(
    .true_input_general_container .true_input_container.start .true_input_label
  ) {
  margin-right: 5px;
}

:global(.true_input_general_container .true_input_container.top) {
  display: block;
}

:global(.true_input_general_container .true_input_container.end) {
  flex-direction: row-reverse;
}

:global(
    .true_input_general_container .true_input_container.end .true_input_label
  ) {
  margin-left: 5px;
}

:global(.true_input_general_container .true_input_container.bottom) {
  flex-direction: column-reverse;
}

:global(
    .true_input_general_container .true_input_container.bottom .true_input_label
  ) {
  margin-top: 5px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.true_input_with_icon
  ) {
  padding-right: 30px;
}

:global(
    .true_input_general_container .true_input_container .true_input.filled
  ) {
  height: 31px;
  position: relative;
  cursor: text;
  background-color: var(--t-contrast-text-color) !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

:global(
    .true_input_general_container .true_input_container .true_input.standard
  ) {
  height: 32px;
  position: relative;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.standard
      input
  ) {
  padding: 4px 0 5px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.standard
      textarea
  ) {
  padding: 4px 0 5px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.standard:not(.true_input_error):hover:before
  ) {
  border-bottom: 2px solid var(--t-border-color);
}

:global(
    .true_input_general_container .true_input_container .true_input.filled input
  ) {
  color: var(--t-text-color);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.filled
      .k-button-solid-base
  ) {
  background-color: transparent;
}

:global(
    .true_input_general_container .true_input_container .true_input.filled input
  ) {
  padding: 4px 12px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.filled
      textarea
  ) {
  padding: 4px 12px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.filled.read-only
  ) {
  background-color: transparent;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.filled.read-only
      input
  ) {
  color: var(--t-text-color);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.filled:not(.read-only):hover
  ) {
  background-color: var(--t-secondary-50);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.filled:not(.true_input_error):hover:before
  ) {
  border-bottom: 2px solid var(--t-border-color);
}

:global(
    .true_input_general_container .true_input_container .true_input.outlined
  ) {
  height: 40px;
  position: relative;
  border-radius: 4px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined
      input
  ) {
  padding: 8.5px 14px;
  border-radius: 4px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined
      textarea
  ) {
  padding: 8.5px 14px;
  border-radius: 4px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined
      .k-datepicker
  ) {
  padding: 8.5px 0;
  border-radius: 4px;
  border: 1px solid var(--t-border-color);
  display: flex;
  align-items: center;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined
      .k-datetimepicker
  ) {
  padding: 8.5px 0;
  border-radius: 4px;
  border: 1px solid var(--t-border-color);
  display: flex;
  align-items: center;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined
      .k-datepicker:hover
  ) {
  border: 1px solid var(--t-border-color);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined
      .k-datetimepicker:hover
  ) {
  border: 1px solid var(--t-border-color);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined
      .k-datepicker:focus-within
  ) {
  border: 2px solid var(--t-primary);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined
      .k-datetimepicker:focus-within
  ) {
  border: 2px solid var(--t-primary);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined:not(.true_input_date)
      input
  ) {
  border: 1px solid var(--t-border-color);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined:not(.true_input_date)
      textarea
  ) {
  border: 1px solid var(--t-border-color);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined.true_input_error
      input
  ) {
  border: 1px solid var(--t-danger);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined.true_input_error
      textarea
  ) {
  border: 1px solid var(--t-danger);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined.true_input_error:focus-within
      input
  ) {
  border: 1px solid var(--t-danger);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined.true_input_error:focus-within
      textarea
  ) {
  border: 1px solid var(--t-danger);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined:not(.true_input_date):not(.read-only):focus-within
      input
  ) {
  border: 2px solid var(--t-primary);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.outlined:not(.true_input_date):not(.read-only):focus-within
      input
  ) {
  border: 2px solid var(--t-primary);
}

:global(
    .true_input_general_container .true_input_container .true_input.multiline
  ) {
  height: auto;
}

:global(
    .true_input_general_container .true_input_container .true_input.resize
  ) {
  resize: both;
  overflow: hidden;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.resize
      textarea
  ) {
  height: 100% !important;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.read-only
      textarea
  ) {
  background-color: transparent;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.read-only
      textarea
  ) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box !important;
  pointer-events: none;
  padding-left: 0px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.read-only
      input
  ) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box !important;
  pointer-events: none;
  padding-left: 0px;
}

:global(
    .true_input_general_container.ident
      .true_input_container
      .true_input.read-only
      textarea
  ) {
  padding-left: 12px;
}

:global(
    .true_input_general_container.ident
      .true_input_container
      .true_input.read-only
      input
  ) {
  padding-left: 12px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.read-only:before
  ) {
  display: none;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.read-only:hover
  ) {
  cursor: default;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.true_input_error:after
  ) {
  border-bottom: 2px solid solid var(--t-danger) !important;
  transform: scaleX(1);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.true_input_error:before
  ) {
  border-bottom: 1px solid var(--t-danger) !important;
  transform: scaleX(1);
}

:global(
    .true_input_general_container .true_input_container .true_input textarea
  ) {
  resize: none;
  background-color: #fff;
}

:global(.true_input_general_container .true_input_container .true_input input) {
  height: 100%;
  font: inherit;
  font-size: var(---t-body-size);
  letter-spacing: inherit;
  color: var(--t-text-color);
  border: 0;
  background-color: transparent;
  margin: 0;
  display: block;
  width: 100%;
}

:global(
    .true_input_general_container .true_input_container .true_input textarea
  ) {
  height: 100%;
  font: inherit;
  font-size: var(---t-body-size);
  letter-spacing: inherit;
  color: var(--t-text-color);
  border: 0;
  background-color: transparent;
  margin: 0;
  display: block;
  width: 100%;
}

:global(
    .true_input_general_container .true_input_container .true_input input:focus
  ) {
  outline: none;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input
      textarea:focus
  ) {
  outline: none;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input
      .k-datepicker
  ) {
  background-color: transparent;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input
      .k-datetimepicker
  ) {
  background-color: transparent;
}

:global(
    .true_input_general_container .true_input_container .true_input .k-input
  ) {
  border-color: transparent;
  font-size: var(---t-body-size);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input
      .k-input:focus-within
  ) {
  box-shadow: none;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input
      .k-button-solid-base
  ) {
  width: 28px;
  height: 28px;
  border-radius: 20px;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input:not(.true_input_date)
      .k-input:focus-within
  ) {
  border: none;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input:not(.outlined)::after
  ) {
  border-bottom: 2px solid var(--t-primary);
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input:not(.outlined)::before
  ) {
  border-bottom: 1px solid var(--t-border-color);
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input:not(.outlined):not(.true_input_error):not(
        .read-only
      ):focus-within:after
  ) {
  transform: scaleX(1) translateX(0);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input.bottom-border
  ) {
  border-bottom: 1px solid var(--t-border-color);
}

:global(.true_input_general_container .true_input_container .true_input_label) {
  display: block;
  color: var(--t-text-color);
}

:global(
    .true_input_general_container
      .true_input_container
      .true_input_label.withoutSize
  ) {
  transform: none;
}

:global(.true_input_general_container .true_input_container .true_input) {
  width: 100%;
}

:global(.true_input_general_container .true_input_container .true_select) {
  width: 100%;
}

:global(
    .true_input_general_container
      .true_input_container
      span.true_input_error_txt:not(.k-datepicker):not(.k-datetimepicker)
  ) {
  color: var(--t-danger);
  font-size: var(--t-small-title-size);
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  display: block;
  white-space: pre-wrap;
}

:global(.true_input_general_container .true_input_helper_text) {
  color: var(--t-text-color);
  opacity: 0;
  display: block;
  font-weight: var(--t-body-bold-weight);
  font-size: var(--t-body-bold-size);
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

:global(.true_input_general_container .k-calendar) {
  z-index: 1000;
}

:global(.true_input_general_container .k-calendar .k-nav-today) {
  color: var(--t-primary);
}

:global(.true_input_general_container .k-calendar .k-calendar-view .k-today) {
  color: var(--t-primary);
}

:global(
    .true_input_general_container .k-calendar .k-calendar-yearview .k-today
  ) {
  color: var(--t-primary);
}

:global(
    .true_input_general_container .k-calendar .k-calendar-navigation li:hover
  ) {
  color: var(--t-primary);
}

:global(
    .true_input_general_container
      .k-calendar
      .k-calendar-navigation
      li.k-state-hover
  ) {
  color: var(--t-primary);
}

:global(.true_input_general_container .k-calendar .k-nav-today:hover) {
  color: var(--t-primary-50);
}

:global(
    .true_input_general_container
      .k-calendar
      .k-calendar-td.k-state-selected
      .k-link
  ) {
  background-color: var(--t-primary);
}

:global(
    .true_input_general_container
      .k-calendar
      .k-calendar-td.k-state-selected
      .k-link:hover
  ) {
  background-color: var(--t-primary);
}

:global(.true_input_general_container:hover .true_input_helper_text) {
  opacity: 1;
}

:global(.true_input_general_container .k-disabled) {
  opacity: 1 !important;
}

:global(.k-colorpicker) {
  background: var(--t-secondary);
  border: none;
  border-bottom: 1px solid var(--t-border-color);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 31px;
  align-items: center;
}
