:global(.true_tabs_container) {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.has_badge {
  padding-top: 10px;
}

:global(.true_tabs_container .k-tabstrip) {
  flex: auto;
  width: 100%;
}

:global(.true_tabs_container .k-active > div[role="tabpanel"]) {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

:global(.true_tabs_container .k-content .k-active) {
  display: flex !important;
}

:global(.true_tabs_container .material > .k-tabstrip-items-wrapper) {
  background-color: var(--t-primary-background);
  border: none;
}

:global(
    .true_tabs_container
      .material
      > .k-tabstrip-items-wrapper
      .k-tabstrip-items
      li.k-active
  ) {
  background: var(--t-primary-background) !important;
  font-size: var(--t-title-size);
  font-weight: var(--t-title-bold-weight);
  border-bottom: 3px solid var(--t-primary);
}

:global(
    .true_tabs_container
      .material
      > .k-tabstrip-items-wrapper
      .k-tabstrip-items
      .k-item
  ) {
  background: transparent;
  text-transform: capitalize;
  padding: 0 25px;
  color: var(--t-text-color);
  font-size: var(--t-title-size);
  font-weight: var(--t-title-weight);
  border: none;
  border-bottom: 1px solid var(--t-border-color);
  margin-bottom: 0;
}

:global(
    .true_tabs_container
      .material
      > .k-tabstrip-items-wrapper
      .k-tabstrip-items
      .k-item
      .k-link
  ) {
  padding: 0;
}

:global(
    .true_tabs_container
      .material
      > .k-tabstrip-items-wrapper
      .k-tabstrip-items
      .k-item
      .k-link
      div
  ) {
  padding: 0;
}

:global(.true_tabs_container .material .k-content) {
  background: var(--t-primary-background);
  padding: 0;
  border: none;
  display: flex;
  overflow: hidden;
}

:global(.true_tabs_container .set-background-transparent .k-content) {
  background: transparent !important;
}

:global(
    .true_tabs_container
      .set-background-transparent
      > .k-tabstrip-items-wrapper
      .k-item
  ) {
  background: transparent;
}

:global(
    .true_tabs_container .set-background-transparent > .k-tabstrip-items-wrapper
  ) {
  background: transparent;
}

:global(.true_tabs_container .square .k-content) {
  background-color: var(--t-primary-background);
  padding: 0;
  border: none;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
  z-index: 2;
  border-top: 1px solid var(--t-border-color);
}

:global(.true_tabs_container .square > .k-tabstrip-items-wrapper) {
  width: 100%;
  margin-left: 5px;
  background-color: var(--t-primary-background);
  border: none;
  z-index: 4;
}

:global(
    .true_tabs_container
      .square
      > .k-tabstrip-items-wrapper
      .k-tabstrip-items
      .k-item
  ) {
  background: var(--t-contrast-text-color);
  text-transform: capitalize;
  padding: 5px 10px;
  color: var(--t-text-color);
  font-size: var(--t-title-bold-size);
  font-weight: var(--t-title-weight);
  border-bottom: 1px solid var(--t-border-color);
  margin-bottom: 0;
}

:global(
    .true_tabs_container
      .square
      > .k-tabstrip-items-wrapper
      .k-tabstrip-items
      .k-item
      .k-link
  ) {
  padding: 0;
}

:global(
    .true_tabs_container
      .square
      > .k-tabstrip-items-wrapper
      .k-tabstrip-items
      .k-item
      .k-link
      .MuiButtonBase-root
  ) {
  padding: 0;
  color: var(--t-text-color);
  font-size: var(--t-title-size);
}

:global(
    .true_tabs_container
      .square
      > .k-tabstrip-items-wrapper
      .k-tabstrip-items
      .k-active
  ) {
  background-color: var(--t-primary-background);
  font-size: var(--t-title-bold-size);
  font-weight: var(--t-title-bold-weight);
  border: 1px solid var(--t-border-color);
  border-radius: 5px 5px 0 0;
  border-bottom: none;
  display: flex;
  flex-direction: column;
}

:global(
    .true_tabs_container
      .square
      > .k-tabstrip-items-wrapper
      .k-tabstrip-items::after
  ) {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--t-border-color);
}

:global(.k-tabstrip-content.k-active) {
  display: flex;
  border: none;
  padding: 0;
  position: relative;
}
