label[class*="true_switch_container"] {
  margin-right: 0;
  justify-content: inherit;
}

:global(.true_switch_container.space_between_label_control) {
  justify-content: space-between;
}

:global(.true_switch_container.invalid .MuiButtonBase-root) {
  color: var(--t-danger);
}

:global(.true_switch_container.invalid .MuiButtonBase-root svg) {
  color: var(--t-danger);
}

:global(.true_switch_container.selected .MuiButtonBase-root) {
  color: var(--t-primary);
}

:global(.true_switch_container.selected .MuiButtonBase-root svg) {
  color: var(--t-primary);
}

:global(.true_switch_container.unselected .MuiButtonBase-root) {
  color: var(--t-border-modal-color);
}

:global(.true_switch_container.unselected .MuiButtonBase-root svg) {
  color: var(--t-border-modal-color);
}

:global(.true_switch_container.disabled .MuiButtonBase-root) {
  color: var(--t-border-color);
}

:global(.true_switch_container.disabled .MuiButtonBase-root svg) {
  color: var(--t-border-color);
}

:global(.true_switch_container.switch-selected .MuiButtonBase-root) {
  color: var(--t-primary);
}

:global(.true_switch_container.switch-selected .MuiButtonBase-root svg) {
  color: var(--t-primary);
}

:global(.true_switch_container.switch-unselected .MuiButtonBase-root) {
  color: #fff;
}

:global(.true_switch_container.switch-unselected .MuiButtonBase-root svg) {
  color: #fff;
}

:global(.true_switch_container .Mui-checked + .MuiSwitch-track) {
  background-color: var(--t-primary) !important;
  opacity: 1;
}

:global(.true_switch_container .MuiFormControlLabel-label) {
  /* max-width: 210px;
    min-width: min-content; */
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

:global(.true_switch_container .PrivateSwitchBase-input) {
  display: none;
}
:global(.true_switch_container .MuiSwitch-thumb) {
  background-color: var(--t-white);
}

:global(.true_switch_container .Mui-checked .MuiSwitch-thumb) {
  background-color: var(--t-primary) !important;
  opacity: 1;
}
