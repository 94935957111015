.insured_selector_container {
  width: 100%;
  height: 100%;
}

.logo_container {
  display: flex;
  justify-content: center;
}

.company_logo {
  width: 50%;
  margin-top: 90px;
}

.insured_selector_container_list {
  background-color: #fff;
  width: 540px;
  height: 540px;
  border-radius: 50%;
  border: 2px solid var(--t-primary);
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
}
